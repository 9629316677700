import { defineModule } from '../utils/helpers';
import { createLoadMore } from '../utils/load-more';

const loadMores: ReturnType<typeof createLoadMore>[] = [];

const getElements = () => ({
  loadMoreElements: document.querySelectorAll<HTMLElement>('[data-load-more]'),
});

export default defineModule(
  () => {
    const { loadMoreElements } = getElements();

    loadMoreElements.forEach((loadMoreElement) => {
      const containerSelector = loadMoreElement.getAttribute(
        'data-load-more-target',
      );
      if (!containerSelector) return;

      const containerElement =
        document.querySelector<HTMLElement>(containerSelector);
      if (!containerElement) return;

      const postType =
        loadMoreElement.getAttribute('data-load-more-post-type') ?? undefined;
      const perPage =
        parseInt(
          loadMoreElement.getAttribute('data-load-more-per-page') || '1',
        ) ?? undefined;
      const offset =
        parseInt(
          loadMoreElement.getAttribute('data-load-more-offset') || '0',
        ) ?? undefined;
      const orderBy =
        loadMoreElement.getAttribute('data-load-more-order-by') ?? undefined;
      const order =
        loadMoreElement.getAttribute('data-load-more-order') ?? undefined;

      const loadMore = createLoadMore({
        containerElement,
        triggerElement: loadMoreElement,
        options: {
          postType,
          perPage,
          offset,
          orderBy,
          order,
        },
      });

      loadMores.push(loadMore);
    });
  },
  () => {
    while (loadMores.length > 0) {
      loadMores.pop()?.destroy();
    }
  },
);
