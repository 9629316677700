import type { VidstackPlayer } from 'vidstack/global/player';
import { createElement, defineModule } from '../../utils/helpers';
import type { CustomEventMap } from '../../global';

let player: Awaited<ReturnType<typeof VidstackPlayer.create>> | null = null;

const onVideoModalOpen = async ({
  detail: { modalElement, triggerElement },
}: CustomEventMap['open-modal:video']) => {
  const { VidstackPlayer, PlyrLayout } = await import('vidstack/global/player');

  const { videoProvider, videoId } = triggerElement.dataset;

  const videoWrapperElement =
    modalElement.querySelector<HTMLElement>('.video__wrapper');
  if (!videoWrapperElement) return;

  const videoElement = createElement('div');
  videoWrapperElement.replaceChildren();
  videoWrapperElement.appendChild(videoElement);

  player = await VidstackPlayer.create({
    target: videoElement,
    src: `${videoProvider}/${videoId}`,
    layout: new PlyrLayout(),
    preload: 'auto',
    autoPlay: true,
  });

  // prevents the video from instantly stopping when clicking
  player.addEventListener('waiting', () => {
    player?.play();
  });

  // reset to beginning, so you don't see related video cards
  player.addEventListener('ended', () => {
    if (!player) return;

    player.currentTime = 0;
  });

  document.documentElement.classList.add('![scrollbar-gutter:auto]');
};

const onVideoModalDismiss = () => {
  document.documentElement.classList.remove('![scrollbar-gutter:auto]');
  player?.destroy();
};

export default defineModule(
  () => {
    document.addEventListener('open-modal:video', onVideoModalOpen);
    document.addEventListener('dismiss-modal:video', onVideoModalDismiss);
  },
  () => {
    document.documentElement.classList.remove('![scrollbar-gutter:auto]');
    document.removeEventListener('open-modal:video', onVideoModalOpen);
    document.removeEventListener('dismiss-modal:video', onVideoModalDismiss);
  },
);
