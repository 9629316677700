interface AccordionOptions {
  onToggle?: (open: boolean) => void;
}

export const createAccordion = (
  el: HTMLElement,
  { onToggle }: AccordionOptions = {},
) => {
  const triggerElement = el.querySelector<HTMLElement>('.accordion__trigger');
  const contentElement = el.querySelector<HTMLElement>('.accordion__content');

  const toggleAccordion = (force?: boolean) => {
    force = force ?? !el.classList.contains('accordion--open');

    el.classList.toggle('accordion--open', force);

    triggerElement?.setAttribute('aria-expanded', `${force}`);
    contentElement?.setAttribute('aria-hidden', `${!force}`);

    onToggle?.(force);

    return force;
  };

  const onTriggerClick = () => toggleAccordion();
  triggerElement?.addEventListener('click', onTriggerClick);

  return {
    el,
    toggle: toggleAccordion,
    open: () => toggleAccordion(true),
    close: () => toggleAccordion(false),
    destroy: () => {
      triggerElement?.removeEventListener('click', onTriggerClick);
    },
  };
};
