import type Splide from '@splidejs/splide';
import { defineModule } from '../utils/helpers';
import { BREAKPOINTS } from '../utils/breakpoints';

const splides: Splide[] = [];

const getElements = () => ({
  splideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--shortcut-slider .splide',
  ),
});

export default defineModule(
  async () => {
    const { splideElements } = getElements();
    if (!splideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    splideElements.forEach((splideElement) => {
      splides.push(
        new Splide(splideElement, {
          autoWidth: true,
          pagination: true,
          focus: 0,
          omitEnd: true,
          gap: '2rem',
          dynamicPagination: {
            mainBullets: 9,
          },
          breakpoints: {
            [BREAKPOINTS.lg]: {
              focus: 'center',
            },
          },
        }).mount(),
      );
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy();
    }
  },
);
