import { observer as aosObserver } from '../vendor/aos';
import { AJAX_API } from './api';

interface LoadMoreOptions {
  containerElement: HTMLElement;
  triggerElement: HTMLElement;
  options?: {
    postType?: string;
    perPage?: number;
    offset?: number;
    orderBy?: string;
    order?: string;
  };
}

export const createLoadMore = ({
  containerElement,
  triggerElement,
  options: {
    postType = 'post',
    perPage = 1,
    offset = 0,
    orderBy = 'date',
    order = 'DESC',
  } = {},
}: LoadMoreOptions) => {
  let currentPage = 0;

  const nextPostCountElement = triggerElement.querySelector<HTMLElement>(
    '[data-load-more-next-page-count]',
  );

  const loadMore = async () => {
    currentPage += 1;

    try {
      triggerElement.setAttribute('disabled', 'true');
      triggerElement.classList.add('load-more--loading');

      const { max, html, remaining } = await AJAX_API.formData({
        action: 'load_more',
        post_type: postType,
        posts_per_page: perPage,
        offset,
        orderby: orderBy,
        order,
        page: currentPage,
      })
        .post()
        .json<{
          max: number;
          count: number;
          remaining: number;
          html: string;
        }>()
        .then((data) => {
          triggerElement.removeAttribute('disabled');
          triggerElement.classList.remove('load-more--loading');

          return data;
        });

      const originalChildrenCount = containerElement.childElementCount;

      containerElement.insertAdjacentHTML('beforeend', html);

      [...containerElement.children]
        .slice(originalChildrenCount - containerElement.childElementCount)
        .forEach((insertedItem) => {
          if (!insertedItem.hasAttribute('data-aos')) return;

          aosObserver.observe(insertedItem);
        });

      if (nextPostCountElement) {
        nextPostCountElement.textContent =
          remaining > perPage ? `${perPage}` : `${remaining}`;
      }

      if (!max || currentPage + 1 === max) {
        triggerElement.setAttribute('disabled', 'true');
        triggerElement.classList.add('load-more--completed');
      }
    } catch (error) {
      currentPage -= 1;

      triggerElement.removeAttribute('disabled');
      triggerElement.classList.remove('load-more--loading');
    }
  };

  triggerElement.addEventListener('click', loadMore);

  return {
    destroy: () => {
      triggerElement.removeEventListener('click', loadMore);
    },
  };
};
