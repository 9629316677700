import type { VidstackPlayer } from 'vidstack/global/player';
import { createElement, defineModule } from '../utils/helpers';

const players: Awaited<ReturnType<typeof VidstackPlayer.create>>[] = [];

const getElements = () => ({
  videoElements: document.querySelectorAll<HTMLElement>(
    '[data-video]:not([data-open-modal="video"])',
  ),
});

export default defineModule(
  async () => {
    const { videoElements } = getElements();
    if (!videoElements.length) return;

    const { VidstackPlayer, PlyrLayout } = await import(
      'vidstack/global/player'
    );

    videoElements.forEach(async (videoElement) => {
      const { videoProvider, videoId, videoAutoplay, videoTitle } =
        videoElement.dataset;

      const player = await VidstackPlayer.create({
        target: videoElement,
        // autoPlay: !!videoAutoplay,
        loop: !!videoAutoplay,
        muted: true, // !!videoAutoplay,
        playsInline: !!videoAutoplay,
        src: `${videoProvider}/${videoId}`,
        layout: new PlyrLayout(),
        controlsDelay: 0,
        load: videoAutoplay ? 'custom' : 'visible',
      });

      if (videoAutoplay) {
        player.classList.add('!pointer-events-none');
        player.addEventListener(
          'can-play',
          () => {
            player?.play().then(() => {
              player.muted = true;
            });
          },
          {
            once: true,
          },
        );
        player.startLoading();
      }

      player.addEventListener('loaded-data', () => {
        const title = videoTitle || player.$state.inferredTitle();
        if (!title) return;

        player.querySelector<HTMLElement>('.plyr')?.appendChild(
          createElement(
            'h3',
            {
              className: 'plyr__title heading--unstyled',
            },
            [title],
          ),
        );
      });

      // prevents the video from instantly stopping when clicking
      player.addEventListener('waiting', () => {
        player.play();
      });
      // forces a normal video to play on intial click
      player.addEventListener(
        'pause',
        () => {
          player.play();
        },
        { once: true },
      );
      // reset to beginning, so you don't see related video cards
      player.addEventListener('ended', () => {
        player.currentTime = 0;
        player.pause();
      });

      players.push(player);
    });
  },
  () => {
    while (players.length > 0) {
      const player = players.pop();
      player?.destroy();
    }
  },
);
