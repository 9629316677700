import { nextTick } from './helpers';

const onTransitionEnd = (e: TransitionEvent) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  e.currentTarget.classList.remove('line-clamp--closing');
  e.currentTarget.classList.add('line-clamp--clamped');
};

export const toggleLineClamp = (el: HTMLElement, force?: boolean) => {
  const isOpen = el.classList.contains('line-clamp--open');
  force = force ?? !isOpen;

  if (force) {
    el.removeEventListener('transitionend', onTransitionEnd);

    el.classList.remove('line-clamp--clamped');

    nextTick(() => {
      el.style.setProperty(
        '--line-clamp-height-expanded',
        `${el.scrollHeight}px`,
      );

      el.classList.add('line-clamp--open');
    });

    return;
  }

  // add closing class in case it was open before
  el.classList.toggle('line-clamp--closing', isOpen);
  el.classList.remove('line-clamp--open');
  el.addEventListener('transitionend', onTransitionEnd, { once: true });
};

export const updateLineClamp = (el: HTMLElement) => {
  el.classList.remove('line-clamp--open');

  el.classList.add('line-clamp--clamped');
  el.style.setProperty('--line-clamp-height-expanded', `${el.scrollHeight}px`);
  el.style.removeProperty('--line-clamp-height');

  nextTick(() => {
    el.style.setProperty(
      '--line-clamp-height',
      `${el.getBoundingClientRect().height}px`,
    );
  });
};

export const isLineClamping = (el: HTMLElement) =>
  el.scrollHeight > el.clientHeight;
