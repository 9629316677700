import { defineModule } from '../../utils/helpers';
import { wrapFormControlElements } from './wrapper';

const getElements = () => ({
  frmFormElements: document.querySelectorAll<HTMLElement>('.frm_forms'),
  formFieldElements: document.querySelectorAll<HTMLElement>('.frm_form_field'),
});

const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (
      mutation.type !== 'childList' ||
      !(mutation.target instanceof HTMLElement)
    ) {
      return;
    }

    if (mutation.target.classList.contains('frm_form_field')) {
      let formFieldWrapperElement = mutation.target.querySelector<HTMLElement>(
        '.frm_control_wrapper',
      );
      if (!formFieldWrapperElement) {
        wrapFormControlElements();
      }

      formFieldWrapperElement = mutation.target.querySelector<HTMLElement>(
        '.frm_control_wrapper',
      );
      if (!formFieldWrapperElement) return;

      const hasError = mutation.target.querySelector<HTMLElement>('.frm_error');
      formFieldWrapperElement.classList.toggle(
        'frm_control_wrapper--error',
        !!hasError,
      );
    }
  });
});

export default defineModule(
  () => {
    const { frmFormElements } = getElements();

    frmFormElements.forEach((frmFormElement) => {
      if (!frmFormElement.parentElement) return;

      observer.observe(frmFormElement.parentElement, {
        childList: true,
        subtree: true,
      });
    });
  },
  () => {
    observer.disconnect();
  },
);
