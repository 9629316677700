import { createAccordion } from '../utils/accordion';
import { defineModule } from '../utils/helpers';

const accordions: ReturnType<typeof createAccordion>[] = [];

const getElements = () => ({
  faqContentBlockElements: document.querySelectorAll<HTMLElement>(
    '.content-block--faq',
  ),
});

export default defineModule(
  () => {
    const { faqContentBlockElements } = getElements();

    faqContentBlockElements.forEach((faqContentBlockElement) => {
      const accordionElements =
        faqContentBlockElement.querySelectorAll<HTMLElement>('.accordion');

      accordionElements.forEach((accordionElement) => {
        const accordion = createAccordion(accordionElement, {
          onToggle: (open) => {
            if (!open) return;

            accordionElements.forEach((_accordionElement) => {
              if (_accordionElement === accordionElement) return;

              accordions
                .find((_accordion) => _accordion.el === _accordionElement)
                ?.close();
            });
          },
        });

        accordions.push(accordion);
      });
    });
  },
  () => {
    while (accordions.length > 0) {
      accordions.pop()?.destroy();
    }
  },
);
