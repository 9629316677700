import { isFunction, type defineModule } from '../utils/helpers';

import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
// @ts-expect-error Package doesn't have types
import SwupGaPlugin from '@swup/ga-plugin';
// @ts-expect-error Package doesn't have types
import SwupGtmPlugin from '@swup/gtm-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupProgressPlugin from '@swup/progress-plugin';

// Modules
// import languageModule from '../components/language';
import aosModule from './aos';
// import loadMoreModule from '../components/load-more';
// import footerMenusModule from '../components/footer/menus';
// import videoModule from '../components/video';
// import modalsModule from '../components/modals';
// import videoModalModule from '../components/modals/video';
// import menuNavigationModule from '../components/menu/navigation';
// import menuScrollModule from '../components/menu/scroll';
// import menuTeamModule from '../components/menu/team-members';
// import selectModule from '../components/select';
// import formsWrapperModule from '../components/forms/wrapper';
// import formsValidateModule from '../components/forms/validate';
// import formsSubmitModule from '../components/forms/submit';
import experiencesBlockModule from '../blocks/experiences';
import shortcutSliderBlockModule from '../blocks/shortcut-slider';
import faqBlockModule from '../blocks/faq';
import imagesSliderBlockModule from '../blocks/images-slider';
import contactBlockModule from '../blocks/contact';
// import popupModule from '../components/modals/popup';

const autoImportedModules = import.meta.glob<ReturnType<typeof defineModule>>(
  ['@/../Templates/blocks/*/script.ts', '../components/**/*.ts'],
  {
    eager: true,
    import: 'default',
  },
);

const modules: ReturnType<typeof defineModule>[] = [
//   languageModule,
  aosModule,
//   loadMoreModule,
//   footerMenusModule,
//   videoModule,
//   modalsModule,
//   videoModalModule,
//   menuNavigationModule,
//   menuScrollModule,
//   menuTeamModule,
//   selectModule,
//   formsWrapperModule,
//   formsValidateModule,
//   formsSubmitModule,
  experiencesBlockModule,
  shortcutSliderBlockModule,
  faqBlockModule,
  imagesSliderBlockModule,
  contactBlockModule,
//   popupModule,
  ...Object.values(autoImportedModules),
];

const setup = () => Promise.all(modules.map((module) => module.setup()));
const cleanup = () =>
  Promise.all(
    modules.map((module) => isFunction(module.cleanup) && module.cleanup()),
  );

const swupNot = '[data-no-swup],[href*="/wp/"],[href*="/app/uploads/"]';
export let swup: Swup | null = null;

/**
 * This if check prevents the nested code from
 * running more than once, if file not imported correctly
 * (Main import uses version parameter)
 */
if (!import.meta.url.endsWith('/dist/app.js')) {
  swup = new Swup({
    animationSelector: '[class*="swup-transition-"]',
    containers: ['.page-wrapper'],
    plugins: [
      new SwupPreloadPlugin(),
      new SwupBodyClassPlugin(),
      new SwupScrollPlugin({
        animateScroll: {
          betweenPages: false,
          samePage: true,
          samePageWithHash: true,
        },
      }),
      new SwupGaPlugin({ gaMeasurementId: window.gaTrackingId }),
      new SwupGtmPlugin(),
      new SwupScriptsPlugin({
        head: false,
        body: true,
      }),
      new SwupHeadPlugin({
        persistTags: import.meta.env.DEV ? 'style[data-vite-dev-id]' : false,
      }),
      new SwupProgressPlugin(),
    ],
    linkSelector: `a[href^="${window.location.origin}"]:not(${swupNot}), a[href^="/"]:not(${swupNot}), a[href^="#"]:not(${swupNot})`,
  });

  swup.hooks.on('visit:end', async () => {
    await setup();
  });

  swup.hooks.on('visit:start', async () => {
    await cleanup();
  });

  setup();
}
