import { createAccordion } from '../../utils/accordion';
import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { defineModule, nextTick } from '../../utils/helpers';

let preventAccordionToggle = false;

const accordions: ReturnType<typeof createAccordion>[] = [];

const getElements = () => ({
  accordionElements: document.querySelectorAll<HTMLElement>(
    '.page-wrapper > footer .accordion',
  ),
});

const onBreakpointChange = () => {
  preventAccordionToggle = true;

  accordions.forEach(({ el, toggle }) => {
    el.classList.add('accordion--no-transitions');

    toggle(BREAKPOINT_MEDIA_QUERIES.lg.matches);

    nextTick(() => {
      el.classList.remove('accordion--no-transitions');
    });
  });

  preventAccordionToggle = false;
};

export default defineModule(
  () => {
    const { accordionElements } = getElements();

    accordionElements.forEach((accordionElement) => {
      const accordion = createAccordion(accordionElement, {
        onToggle: (open) => {
          if (!open || preventAccordionToggle) return;

          accordionElements.forEach((_accordionElement) => {
            if (_accordionElement === accordionElement) return;

            accordions
              .find((_accordion) => _accordion.el === _accordionElement)
              ?.close();
          });
        },
      });

      accordions.push(accordion);
    });

    onBreakpointChange();
    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener('change', onBreakpointChange);
  },
  () => {
    while (accordions.length > 0) {
      accordions.pop()?.destroy();
    }

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      onBreakpointChange,
    );
  },
);
