import { defineModule } from '../utils/helpers';

const getElements = () => ({
  resendButtonElements: document.querySelectorAll<HTMLElement>(
    '.content-block--contact .contact__resend',
  ),
});

const onResendClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const contentBlockElement = e.currentTarget.closest<HTMLElement>(
    '.content-block--contact',
  );
  if (!contentBlockElement) return;

  contentBlockElement.classList.remove('content-block--form-completed');
  contentBlockElement.querySelector<HTMLElement>('.frm_message')?.remove();
};

export default defineModule(
  () => {
    const { resendButtonElements } = getElements();

    resendButtonElements.forEach((resendButtonElement) => {
      resendButtonElement.addEventListener('click', onResendClick);
    });
  },
  () => {
    const { resendButtonElements } = getElements();

    resendButtonElements.forEach((resendButtonElement) => {
      resendButtonElement.removeEventListener('click', onResendClick);
    });
  },
);
