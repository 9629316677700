import {
  type Splide,
  type Components,
  type Options,
  EventInterface,
  EVENT_PAGINATION_UPDATED,
  type PaginationItem,
  type PaginationData,
} from '@splidejs/splide';
import { merge } from 'ts-deepmerge';
import type {
  DynamicPaginationComponent,
  DynamicPaginationOptions,
} from './types';
import {
  CLASS_PAGINATION_DYNAMIC,
  CLASS_PAGINATION_PAGE_HIDDEN,
  DEFAULT_OPTIONS,
} from './constants';

declare module '@splidejs/splide' {
  interface Options {
    dynamicPagination?: DynamicPaginationOptions | boolean;
  }

  interface Components {
    DynamicPagination?: DynamicPaginationComponent;
  }
}

export const DynamicPagination = (
  splide: Splide,
  { Pagination, Controller, Elements }: Components,
  options: Options,
): DynamicPaginationComponent => {
  let dynamicPaginationOptions: DynamicPaginationOptions = {};
  const { on, off } = EventInterface(splide);

  const updateDynamicPagination = (
    _data?: PaginationData,
    _prev?: PaginationItem,
    _curr?: PaginationItem,
  ) => {
    const currentIndex = Controller.getIndex();
    const _prevIndex = Controller.getIndex(true);
    const _slideCount = Elements.slides.length;

    const { mainBullets } = dynamicPaginationOptions;
    if (!mainBullets) return;

    const halfItems = Math.floor(mainBullets / 2);
    let firstIndex = Math.max(0, currentIndex - halfItems);
    const lastIndex = Math.min(
      Pagination.items.length - 1,
      firstIndex + mainBullets - 1,
    );

    // Adjust firstIndex if needed to keep lastIndex in range.
    if (lastIndex - firstIndex + 1 < mainBullets) {
      firstIndex = Math.max(0, lastIndex - mainBullets + 1);
    }

    Pagination.items.forEach((item, index) => {
      if (index >= firstIndex && index <= lastIndex) {
        item.button.classList.remove(CLASS_PAGINATION_PAGE_HIDDEN);
      } else {
        item.button.classList.add(CLASS_PAGINATION_PAGE_HIDDEN);
      }
    });
  };

  return {
    setup() {
      const { dynamicPagination } = options;
      dynamicPaginationOptions = merge(
        DEFAULT_OPTIONS,
        typeof dynamicPagination === 'object' ? dynamicPagination : {},
      );
    },
    mount() {
      // Pagination must be enabled
      if (!options.pagination || !options.dynamicPagination) return;

      // Don't bother when there's not enough slides
      const { mainBullets } = dynamicPaginationOptions;
      if (mainBullets && mainBullets >= Elements.slides.length) return;

      updateDynamicPagination();
      on(EVENT_PAGINATION_UPDATED, updateDynamicPagination);

      Elements.pagination?.classList.add(CLASS_PAGINATION_DYNAMIC);
    },
    destroy(completely) {
      off(EVENT_PAGINATION_UPDATED);

      if (completely) {
        Elements.pagination?.classList.remove(CLASS_PAGINATION_DYNAMIC);
        Pagination.items.forEach((item) => {
          item.button.classList.remove(CLASS_PAGINATION_PAGE_HIDDEN);
        });
      }
    },
  };
};
