import { defineModule } from '../../utils/helpers';

const onFormComplete = (
  event: Record<string, any>,
  form: HTMLFormElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  response: { errors: string[]; content: string; pass: boolean },
) => {
  const formIdElement = form.querySelector<HTMLInputElement>(
    'input[name="form_id"]',
  );
  if (!formIdElement) return;

  const formContainerElement = document.getElementById(
    `frm_form_${formIdElement.value}_container`,
  );
  if (!formContainerElement) return;
  formContainerElement.classList.add('form--completed');

  const contentBlock =
    formContainerElement.closest<HTMLElement>('.content-block');
  contentBlock?.classList.add('content-block--form-completed');

  const modalElement = formContainerElement.closest<HTMLElement>('.modal');
  modalElement?.classList.add('modal--form-completed');
};

export default defineModule(
  () => {
    jQuery(document).on('frmFormComplete', onFormComplete);
  },
  () => {
    jQuery(document).off('frmFormComplete', onFormComplete);
  },
);
