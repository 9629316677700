import type Splide from '@splidejs/splide';
import debounce from 'lodash-es/debounce';
import { defineModule } from '../utils/helpers';
import { BREAKPOINTS } from '../utils/breakpoints';
import {
  isLineClamping,
  toggleLineClamp,
  updateLineClamp,
} from '../utils/line-clamp';

const splides: Splide[] = [];

const getElements = () => ({
  splideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--experiences .splide',
  ),
});

const toggleSlide = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const lineClampElements = e.currentTarget
    .closest<HTMLElement>('.splide')
    ?.querySelectorAll<HTMLElement>('.line-clamp');
  if (!lineClampElements) return;

  const currentLineClampElement = e.currentTarget
    .closest<HTMLElement>('.splide__slide')
    ?.querySelector<HTMLElement>('.line-clamp');

  lineClampElements.forEach((lineClampElement) => {
    toggleLineClamp(
      lineClampElement,
      lineClampElement === currentLineClampElement ? undefined : false,
    );
  });
};

const onWindowResize = () => {
  splides.forEach((splide) => {
    splide.Components.Slides.forEach(({ slide }) => {
      const lineClampElement = slide.querySelector<HTMLElement>('.line-clamp');
      if (!lineClampElement) return;

      updateLineClamp(lineClampElement);

      const slideTogglerElement =
        slide.querySelector<HTMLElement>('.slide__toggler');

      slideTogglerElement?.classList.toggle(
        'hidden',
        !isLineClamping(lineClampElement),
      );
    });
  });
};

const debouncedOnWindowResize = debounce(onWindowResize, 50);

export default defineModule(
  async () => {
    const { splideElements } = getElements();
    if (!splideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    splideElements.forEach((splideElement) => {
      const splide = new Splide(splideElement, {
        autoWidth: true,
        pagination: true,
        focus: 0,
        omitEnd: true,
        gap: '2rem',
        dynamicPagination: {
          mainBullets: 9,
        },
        breakpoints: {
          [BREAKPOINTS.lg]: {
            focus: 'center',
          },
        },
      }).mount();

      splide.Components.Slides.forEach(({ slide }) => {
        const slideTogglerElement =
          slide.querySelector<HTMLElement>('.slide__toggler');

        slideTogglerElement?.addEventListener('click', toggleSlide);
      });

      splides.push(splide);
    });

    window.addEventListener('resize', debouncedOnWindowResize);
    onWindowResize();
  },
  () => {
    while (splides.length > 0) {
      const splide = splides.pop();

      splide?.Components.Slides.forEach(({ slide }) => {
        const slideTogglerElement =
          slide.querySelector<HTMLElement>('.slide__togglers');

        slideTogglerElement?.removeEventListener('click', toggleSlide);
      });

      splide?.destroy();
    }

    window.removeEventListener('resize', debouncedOnWindowResize);
  },
);
