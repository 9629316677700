import {
  computePosition,
  shift,
  size,
  autoUpdate,
  offset,
} from '@floating-ui/dom';
import { v4 as uuidv4 } from 'uuid';
import { createElement, defineModule } from '../utils/helpers';

const getElements = () => ({
  selectElements: document.querySelectorAll<HTMLSelectElement>(
    'select:not([multiple],.rn-custom-select)',
  ),
});

const enhancedSelectElements: ReturnType<typeof createEnhancedSelect>[] = [];

const createEnhancedSelect = (selectElement: HTMLSelectElement) => {
  selectElement.classList.add('no-touch:!hidden');

  const id = uuidv4();

  let isOpen = false;

  /* ----------------------------- Setup Elements ----------------------------- */
  const enhancedSelectElementText = createElement(
    'span',
    {
      className: 'select__text',
    },
    [
      [...selectElement.options].find((option) => option.selected)
        ?.textContent ?? '',
    ],
  );

  const enhancedSelectElement = createElement(
    'button',
    {
      ariaExpanded: false,
      className: 'select',
      type: 'button',
    },
    [enhancedSelectElementText],
  );

  enhancedSelectElement.setAttribute('aria-controls', `select-${id}`);

  const listElement = createElement('div', {
    id: `select-${id}`,
    role: 'listbox',
    ariaHidden: true,
    ariaHasPopup: 'listbox',
    className: 'select__list',
  });

  const optionElements = [...selectElement.options].map((option, i) => {
    const optionElement = createElement(
      'button',
      {
        role: 'option',
        type: 'button',
        ariaSelected: option.selected,
        hidden: option.defaultSelected && i === 0,
        className: 'select__option',
        value: option.value,
        tabIndex: 0,
      },
      [option.textContent ?? ''],
    );

    if (option.disabled) {
      optionElement.setAttribute('disabled', '');
    }

    return optionElement;
  });

  listElement.append(...optionElements);
  selectElement.parentElement?.insertBefore(
    enhancedSelectElement,
    selectElement.nextSibling,
  );
  enhancedSelectElement.parentElement?.insertBefore(
    listElement,
    enhancedSelectElement.nextSibling,
  );
  /* -------------------------------------------------------------------------- */

  const toggleListElement = (force?: boolean) => {
    force = force ?? !isOpen;

    if (force) {
      enhancedSelectElement.ariaExpanded = 'true';
      listElement.ariaHidden = 'false';
    } else {
      enhancedSelectElement.ariaExpanded = 'false';
      listElement.ariaHidden = 'true';
    }

    isOpen = force;
  };

  /* -------------------------- Setup Event Listeners ------------------------- */
  const handleOriginalSelectElementInput = (e: Event) => {
    if (!(e.target instanceof HTMLSelectElement)) return;

    optionElements.forEach((optionElement) => {
      if (!(e.target instanceof HTMLSelectElement)) return;
      // eslint-disable-next-line eqeqeq
      if (optionElement.value == e.target.value) {
        optionElement.setAttribute('aria-selected', 'true');
        selectElement.value = optionElement.value;
        enhancedSelectElementText.textContent = optionElement.textContent;
      } else {
        optionElement.setAttribute('aria-selected', 'false');
      }
    });
  };
  selectElement.addEventListener('input', handleOriginalSelectElementInput, {
    passive: true,
  });

  const handleEnhancedSelectElementClick = () => toggleListElement();
  enhancedSelectElement.addEventListener(
    'click',
    handleEnhancedSelectElementClick,
    { passive: true },
  );

  const handleEnhancedSelectElementOutsideClick = (e: Event) => {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    if (e.target !== enhancedSelectElement) {
      toggleListElement(false);
    }
  };
  window.addEventListener('click', handleEnhancedSelectElementOutsideClick, {
    passive: true,
  });

  const handleEscapeKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode !== 27 || !isOpen) return;

    toggleListElement(false);
  };
  window.addEventListener('keydown', handleEscapeKeyPress, { passive: true });

  const handleOptionElementClick = (e: Event) => {
    if (!(e.target instanceof HTMLButtonElement)) return;

    optionElements.forEach((optionElement) => {
      if (optionElement === e.target) {
        optionElement.setAttribute('aria-selected', 'true');
        selectElement.value = optionElement.value;
        enhancedSelectElementText.textContent = optionElement.textContent;
        selectElement.dispatchEvent(new Event('input'));
      } else {
        optionElement.setAttribute('aria-selected', 'false');
      }
    });
  };
  optionElements.forEach((optionElement) => {
    optionElement.addEventListener('click', handleOptionElementClick, {
      passive: true,
    });
  });
  /* -------------------------------------------------------------------------- */

  /* ---------------------------- Setup FloatingUI ---------------------------- */
  const updatePosition = () => {
    computePosition(enhancedSelectElement, listElement, {
      placement: 'bottom-start',
      middleware: [
        offset(8),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        }),
        shift(),
      ],
    }).then(({ x, y }) => {
      Object.assign(listElement.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  };

  const cleanupAutoUpdate = autoUpdate(
    enhancedSelectElement,
    listElement,
    updatePosition,
  );
  /* -------------------------------------------------------------------------- */

  return {
    /**
     * Removes all event listeners
     */
    cleanup() {
      enhancedSelectElement.removeEventListener(
        'click',
        handleEnhancedSelectElementClick,
      );
      window.removeEventListener(
        'click',
        handleEnhancedSelectElementOutsideClick,
      );
      selectElement.removeEventListener(
        'input',
        handleOriginalSelectElementInput,
      );
      window.removeEventListener('keydown', handleEscapeKeyPress);
      optionElements.forEach((optionElement) => {
        optionElement.removeEventListener('click', handleOptionElementClick);
      });

      cleanupAutoUpdate();
    },
    /**
     * Removes all event listeners
     * and destroys all custom elements
     */
    destroy() {
      this.cleanup();

      enhancedSelectElement.remove();
      listElement.remove();
      selectElement.style.display = '';
    },
  };
};

export default defineModule(
  () => {
    const { selectElements } = getElements();

    selectElements.forEach((selectElement) =>
      enhancedSelectElements.push(createEnhancedSelect(selectElement)),
    );
  },
  () => {
    if (!enhancedSelectElements.length) return;

    while (enhancedSelectElements.length > 0) {
      enhancedSelectElements.pop()?.cleanup();
    }
  },
);
