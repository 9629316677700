import { createElement, defineModule, wrapElement } from '../../utils/helpers';

const getElements = () => ({
  formElements: document.querySelectorAll<HTMLElement>('.frm_forms'),
  formFieldElements: document.querySelectorAll<HTMLElement>('.frm_form_field'),
});

export const wrapFormControlElements = () => {
  const { formFieldElements } = getElements();

  formFieldElements.forEach((formFieldElement) => {
    let formFieldControlElement: HTMLElement | null | undefined = null;
    formFieldControlElement = [...formFieldElement.children].find(
      (child) =>
        child instanceof HTMLElement &&
        child.getAttribute('name')?.startsWith('item_meta'),
    ) as HTMLElement | undefined;
    
    if (!formFieldControlElement) return;

    wrapElement(
      formFieldControlElement,
      createElement('div', {
        className: 'frm_control_wrapper',
      }),
    );
  });
};

export default defineModule(() => {
  wrapFormControlElements();
});
