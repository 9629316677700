import { computePosition, flip, offset, shift } from '@floating-ui/dom';
import { createElement, defineModule } from '../../utils/helpers';

const getElements = () => ({
  teamMembers: document.querySelectorAll<HTMLLIElement>(
    '.navbar [data-team-member-image]',
  ),
});

let tooltip: HTMLImageElement | null = null;
const getTooltip = (url?: string) => {
  if (!tooltip) {
    tooltip = createElement('img', {
      id: 'tooltip--teammember',
    }) as HTMLImageElement;
    document.body.append(tooltip);
  }
  if (url) {
    tooltip.src = url;
  }

  return tooltip;
};

const onTeamMemberMouseMove = ({ clientX, clientY }: MouseEvent) => {
  const floating = getTooltip();

  const virtualEl = {
    getBoundingClientRect() {
      return {
        width: 0,
        height: 0,
        x: clientX,
        y: clientY,
        left: clientX,
        right: clientX,
        top: clientY,
        bottom: clientY,
      };
    },
  };

  computePosition(virtualEl, floating, {
    placement: 'right-start',
    middleware: [offset(5), flip(), shift()],
  }).then(({ x, y }) => {
    Object.assign(floating.style, {
      top: `${y}px`,
      left: `${x}px`,
    });
  });
};
const onTeamMemberMouseEnter = (e: Event) => {
  const link = e.currentTarget as HTMLAnchorElement;
  const url = link.dataset.teamMemberImage as string;
  getTooltip(url).classList.remove('hidden');

  link.addEventListener('mousemove', onTeamMemberMouseMove);
};
const onTeamMemberMouseLeave = () => {
  getTooltip().classList.add('hidden');
};

export default defineModule(
  () => {
    const { teamMembers } = getElements();

    teamMembers.forEach((link) => {
      link.addEventListener('mouseenter', onTeamMemberMouseEnter, {
        passive: true,
      });
      link.addEventListener('mouseleave', onTeamMemberMouseLeave, {
        passive: true,
      });
    });
  },
  () => {
    const { teamMembers } = getElements();
    teamMembers.forEach((link) => {
      link.removeEventListener('mouseenter', onTeamMemberMouseEnter);
      link.removeEventListener('mouseleave', onTeamMemberMouseLeave);
    });

    tooltip?.remove();
    tooltip = null;
  },
);
