export const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  'full-hd': 1920,
} as const;

type BreakpointMediaQueries = Record<keyof typeof BREAKPOINTS, MediaQueryList>;

export const BREAKPOINT_MEDIA_QUERIES = (
  Object.keys(BREAKPOINTS) as (keyof typeof BREAKPOINTS)[]
).reduce<BreakpointMediaQueries>(
  (breakpoints, breakpoint) => ({
    ...breakpoints,
    [breakpoint]: window.matchMedia(
      typeof BREAKPOINTS[breakpoint] === 'number'
        ? `(min-width: ${BREAKPOINTS[breakpoint]}px)`
        : (BREAKPOINTS[breakpoint] as unknown as string),
    ),
  }),
  {} as BreakpointMediaQueries,
);
