import { defineModule } from '../../utils/helpers';
import { Overlay, hasOpenOverlay } from '../../utils/overlays';

let lastScrollTop = 0;
const SCROLL_TRESHOLD = 100;

const getElements = () => ({
  navbar: document.querySelector<HTMLElement>('.navbar'),
});

const onScroll = () => {
  if (hasOpenOverlay(Overlay.MENU)) return;

  const { navbar } = getElements();

  const currentScrollTop =
    window.pageYOffset || document.documentElement.scrollTop;

  navbar?.classList.toggle(
    'navbar--scroll',
    currentScrollTop > SCROLL_TRESHOLD && currentScrollTop > lastScrollTop,
  );

  lastScrollTop = currentScrollTop;
};

export default defineModule(
  () => {
    window.addEventListener('scroll', onScroll);
  },
  () => {
    window.removeEventListener('scroll', onScroll);
  },
);
