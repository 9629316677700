import Cookie from 'js-cookie';
import { defineModule } from '../utils/helpers';

let language = Cookie.get('wp-wpml_current_language');

export default defineModule(() => {
  const currentLanguage = Cookie.get('wp-wpml_current_language');

  if (language !== currentLanguage) {
    window.location.reload();
    return;
  }

  language = currentLanguage;
});
