export const nextTick = (callback: Function) => {
  setTimeout(callback, 0);
};

export const createElement = (
  tag: keyof HTMLElementTagNameMap,
  options?: Record<string, any> | null,
  children?: HTMLElement[] | string[] | HTMLCollection,
) => {
  const node = Object.assign(document.createElement(tag), options);
  if (children?.length) node.append(...children);
  return node;
};

/**
 * Define a module (useful when doing something with Swup)
 * @param setup Module setup function
 * @param cleanup Module cleanup function
 * @returns Module object
 */
export const defineModule = (
  setup: () => Promise<void> | void,
  cleanup?: () => Promise<void> | void,
) => ({
  setup,
  cleanup,
});

/**
 * Check if the given variable is a function
 * @param fn Variable to check
 * @returns boolean value based on if `fn` is a function
 */
export const isFunction = (fn: unknown): fn is Function =>
  typeof fn === 'function';

export const wrapElement = (el: HTMLElement, wrapper: HTMLElement) => {
  if (!el.parentElement) return;

  el.parentElement.insertBefore(wrapper, el);
  wrapper.appendChild(el);
};
